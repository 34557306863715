.footer--logo {
    width: 100%;
    height: auto;
}

.footer--link {
    color: inherit;
    cursor: pointer;
    padding: 0.25rem;
    text-decoration: underline;
}
