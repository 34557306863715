.footer--logo {
    width: 100%;
    height: auto;
}

.footer--link {
    color: inherit;
    cursor: pointer;
    padding: 0.25rem;
    text-decoration: underline;
}

.header-logo {
    height: 2.6rem !important;
}

.disp-none{
    display: none;
}
.pd-t--header-height{
    padding-top: 3rem;
}
